import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="placeholder">
      <h2>Check back soon for class updates!</h2>
    </div>
  </Layout>
)

export default IndexPage
